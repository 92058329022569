@import url("https://fonts.googleapis.com/css2?family=Uchen&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arvo&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

.main {
  width: 100vw;
  background: linear-gradient(
    90deg,
    rgba(23, 6, 85, 1) 0%,
    rgba(176, 17, 185, 1) 100%
  );
  overflow-x: hidden;
  background: rgb(55, 0, 63);
  background: radial-gradient(
    circle,
    rgba(55, 0, 63, 1) 0%,
    rgba(155, 17, 168, 0.7178221630449055) 100%
  );
  background: rgb(55, 0, 63);
  background: linear-gradient(
    232deg,
    rgba(55, 0, 63, 1) 0%,
    rgba(155, 17, 168, 0.7178221630449055) 100%
  );
  background: rgb(4, 94, 97);
  background: linear-gradient(
    125deg,
    rgba(4, 94, 97, 0.7178221630449055) 0%,
    rgba(102, 0, 102, 1) 88%
  );
  background: rgb(0, 199, 205);
  background: linear-gradient(
    125deg,
    rgba(0, 199, 205, 0.7178221630449055) 0%,
    rgba(75, 8, 75, 1) 75%
  );
  background: rgb(210, 64, 64);
  background: linear-gradient(
    125deg,
    rgba(210, 64, 64, 0.7178221630449055) 0%,
    rgba(106, 0, 90, 1) 67%
  );

  background: rgb(210, 64, 64);
  background: radial-gradient(
    circle,
    rgba(210, 64, 64, 0.7178221630449055) 0%,
    rgba(106, 0, 90, 1) 85%
  );
}

body {
  overflow-x: hidden;
}

.title {
  text-align: center;
  font-size: 3.5rem;
  font-family: "Bree+Serif", serif;
  color: white;
  font-weight: 900;
  padding-top: 2rem;
}

.sub_text {
  text-align: center;
  font-size: 1.65rem;
  font-family: "Bree+Serif";
  color: white;
  font-weight: 500;
  width: 90vw;
  margin-left: 5vw;
}

.sub_text1 {
  text-align: center;
  font-size: 3.5rem;
  font-family: "Bree+Serif", serif;
  color: white;
  font-weight: 600;
  padding-top: 2rem;
  padding-bottom: 1rem;
  width: 90vw;
  margin-left: 5vw;
}
.sub_text2 {
  text-align: center;
  font-size: 1.35rem;
  font-family: "Bree+Serif", serif;
  color: white;
  font-weight: 500;
  padding-top: 2rem;
  margin-bottom: 2rem;
  width: 90vw;
  margin-left: 5vw;
}
.calender-box {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.c-month {
  width: 15rem;
  font-family: "Bree+Serif", serif;
  height: 7rem;
  background-color: rgb(255, 255, 255, 0.7);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 1.5rem;
  opacity: 0.75;
  border-radius: 20px;
  font-weight: 500;
  margin: 2rem 3rem;
  color: black;
  box-shadow: 2px 2px 5px;
  transition: 0.5s;
}
.c-month:hover {
  transform: scale(1.1);
}

.c-month div {
  margin-top: 2.2rem;
}

.music_button {
  border: none;
  padding: 0.5rem 2rem;
  font-family: "Bree+Serif", serif;
  font-size: 1.45rem;
  border-radius: 20px;
  font-style: italic;
  background-color: rgba(0, 0, 0, 0.482);
  color: white;
  box-shadow: 2px 3px 3px black;
  margin-bottom: 2rem;
  outline: none;
}

.music_button:hover {
  cursor: pointer;
}


@media only screen and (max-width: 600px) {
  .title{
    font-size: 6.5vw;
  }
  .sub_text1{
    font-size: 6.5vw;
  }

  .sub_text2{
    font-size: 3.7vw;
    width: 90vw;
    margin-left: 5vw;
  }
  .sub_text{
    font-size: 4vw;
    width: 90vw;
    margin-left: 5vw;
  }
  .music_button{
    font-size: 1rem;
  }
  .c-month{
    font-size: 5.5vw;
    margin-bottom: .75rem;
  }
  .c-month:hover{
    transform: none;
  }

}